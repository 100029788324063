<template>
    <div>
        <input type="text" 
            class="form-control" 
            :name="id" 
            :id="id" 
            :placeholder="placeholder" 
            ref="input" 
            autocomplete="off" 
            v-model="item" 
            @input="filterItems" 
            @focus="filterItems"
            @mouseover="hoverInput = true" 
            @mouseleave="hoverInput = false" 
            @keydown.esc="hoverInput = false"
            @keydown.up.prevent="upSelect()"
            @keydown.down.prevent="downSelect()"
            @keydown.enter.stop.prevent="selectItem(filteredItems[highlightIndex])"
        >
        <div class="search-selecter" 
            v-show="filteredItems.length && (hoverInput || hoverDiv)" 
            @mouseover="hoverDiv = true" 
            @mouseleave="hoverDiv = false">
            <ul v-if="datatype === 'product'">
                <li v-for="(item, index) in filteredItems" 
                    :key="item.id" 
                    @click="selectItem(item)" 
                    :class="index === highlightIndex && !hoverDiv ? 'highlight': ''" 
                    v-show="item.seq == seq">
                    {{item.sku}}<span class="text-blue">&nbsp;&#x25CF;&nbsp;</span>{{item.name_en}}<br>
                    {{item.name_zh}}
                </li>
            </ul>
            <ul v-else-if="datatype === 'entity'">
                <li v-for="(item, index) in filteredItems" 
                    :key="item.id" 
                    @click="selectItem(item)" 
                    :class="index === highlightIndex && !hoverDiv ? 'highlight' : ''" 
                    v-show="item.seq == seq">
                    <template v-if="item.entity_type_id === 1 || item.entity_type_id === 2">
                        {{item.name_en}}<span class="text-blue">&nbsp;&#x25CF;&nbsp;</span>{{item.entity_number}}<br>
                        {{item.name_zh}}
                    </template>
                    <template v-if="item.entity_type_id === 3">
                        <span v-if="item.name_en !== null && item.name_en !== item.name">{{item.name_en}}<span class="text-blue">&nbsp;&#x25CF;&nbsp;</span></span>{{item.name}}<span class="text-blue">&nbsp;&#x25CF;&nbsp;</span>{{item.entity_number}}<br>
                        {{item.telephone}}&nbsp;{{item.telephone2}}&nbsp;{{item.mobile}}
                    </template>
                </li>
            </ul>
            <ul v-else-if="datatype === 'entityCurrent'">
                <li v-for="(item, index) in filteredItems" 
                    :key="item.id" 
                    @click="selectItem(item)" 
                    :class="index === highlightIndex && !hoverDiv ? 'highlight' : ''" 
                    v-show="item.seq == seq">
                    {{item.entity_number}}<span class="text-blue">&nbsp;&#x25CF;&nbsp;</span>{{item.entity_name}}<br>
                    Current: {{item.current}}
                </li>
            </ul>
        </div>
    </div>
</template>
    
<script>
import axios from 'axios';

export default {
    props: [
        'id',
        'datatype',
        'placeholder',
        'ajaxUrl',
        'selectedValue',
    ],
    data() {
        return {
            item: this.selectedValue || '',
            filteredItems: [],
            selectedItem: '',
            hoverInput: false,
            hoverDiv: false,
            highlightIndex: -1,
            seq: 0,
        };
    },
    methods: {
        filterItemsReset() {
            this.filteredItems = [];
            this.hoverInput = false;
            this.hoverDiv = false;
            this.highlightIndex = -1;
        },
        filterItems() {
            this.hoverInput = true;

            if (!this.item) {
                this.filterItemsReset();
            } else {
                const url = this.ajaxUrl;
                this.seq = new Date().getTime();
                const paramsObj = { search: this.item, seq: this.seq };
                
                axios.get(url, { params: paramsObj }).then(
                    (response) => {
                        console.log("response.data", response.data );
                        if (response.data.length > 0 && response.data[0].seq == this.seq) {
                            this.filteredItems = response.data.slice(0, 10);
                        } else if (response.data.length === 0) {
                            this.filteredItems = [];
                        }
                        console.log("filterItems", this.filteredItems );
                    },
                    () => {
                        console.log("filterItems FAIL");
                    }
                );
            }
        },
        selectItem(item) {
            if (item) {
                this.$emit('selectitem', item);
                this.filterItemsReset();
            }
        },
        upSelect() {
            if ((this.hoverInput || this.hoverDiv) && this.highlightIndex > 0) {
                this.highlightIndex -= 1;
            }
        },
        downSelect() {
            if ((this.hoverInput || this.hoverDiv) && this.highlightIndex < this.filteredItems.length - 1) {
                this.highlightIndex += 1;
            }
        },
        focus() {
            this.item = '';
            this.$refs.input.focus();
            this.filterItemsReset();
        },
    },
    watch: {
        selectedValue(value) {
            this.item = value;
        }
    }
};
</script>
    

